.videography {
    padding-top: 100px;

    background-size: cover;
    background-position: 20%;
    background-blend-mode: multiply;
    background-clip: inherit;
}

.videoSection {
    position: relative;
    width: 100%;
    margin: 40px auto 0px auto;
    background: url(../resources/filmbackground-transparent-01.svg);
}

.video-container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
}
.VideoMain{
    width: 70vw;
    height: 39.375vw;
    max-width: 1000px;
    max-height: 562.5px;
}
.Vidarrow {
    position: absolute;
    top: 40%;
    
    cursor: pointer;
}

.Vidarrow img {
    margin-top: -15px;
    width: 4vw;
    height: 5vw;
}
.Vidarrow img:hover{
transform: scaleX(1.1);
transform-origin: center;
transition: all .15s ease-in-out;
}
.Vidarrow img:not(:hover){
    transform: scale(1);
    transition: all .15s ease-in-out;  
    transform-origin: center;
}
.leftArrow {
    margin-left: 2.5vw;
    left: 0;
}

.rightArrow {
    right: 0;
    margin-right: 2.5vw;
}
.vidHeader{
font-size: 6em;
width: 80%;
margin: 0 auto;
margin-top: 60px;
line-height: .9em;
}
.videotitle2 {
font-size: 4em;
width: 80%;
padding-top: 30px;
margin: 0 auto;
}
.videotitle {
    font-size: 3.6em;
    width: 70vw;
    max-width: 1000px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: -40px;
    color: white;
    background-color: #F3AF22;
    }

.twoColumn{
display: flex;
margin: 0 auto;
flex-direction: row;    
flex-wrap: wrap;
justify-content: center;
gap: 20px;
max-width: 1600px;
}
.oneColumn {
    display: flex;
    margin: 0 auto;
    flex-direction: column;    
    justify-content: center;
    gap: 20px;
    max-width: 1600px;    
}
.oneColumnItem{
    object-fit: cover;
    max-width: 1200px;
    width: 100%;   
    margin: 0 auto;
}
.flexHeader{
width: 100%;

}
.columnItem{
    flex-direction: row;
    max-width: 40%;
}
.columnImage {
object-fit: cover;
max-height: 1000px;
width: 30%;
}
.textLeft{
text-align: left;
font-size: 1.2em;
}
.droneVideo{
width: 100%;
margin-bottom: 40px;
margin-top: 30px;
}
.textCenter{
    text-align: center;
    font-size: 1.2em;
    }
.horizontalLine {
height: 3px;
margin: 10px 0px 20px 0px;
width: 70%;
max-width: 1200px;
background-color: #241B0F;
opacity: 7%;
border-radius: 25px;
}
.horizCenter{
margin: 0 auto;
height: 3px;
width: 70%;
background-color: #241B0F;
opacity: 7%;
max-width: 1200px;
}
.whiteo{
background-color: white;
height: auto;
width: 100%;
padding: 30px 0px 70px 0px;
}
.videodescription{
font-size: 1.6em;
width: 80%;
max-width: 1000px;
margin: 0 auto;
padding-top: 30px;
padding-bottom: 50px;
}
.fade {
    animation: fadeOut 0.4s, fadeIn 0.4s 0.4s;
}

.fade-out {
    animation: fadeOut 0.4s forwards;
}

.fade-in {
    animation: fadeIn 0.4s forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@media screen and (max-width: 1600px){
    .videodescription{
        font-size: 2em;
        width: 1100px;
        margin: 0 auto;
        
        padding: 40px 0px;
        }   
}
@media screen and (max-width: 1280px){
    .videodescription{
        font-size: 1.4em;
        width: 70%;
        margin: 0 auto;
    
        padding: 40px 0px;
        }   
        .textCenter{
            text-align: center;
            font-size: 1.2em;
            width: 70%;
            }
}
@media screen and (max-width:768px){
    .videotitle {
        font-size: 2.5em;
        width: 70vw;
        max-width: 1000px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: -40px;
        color: white;
        background-color: #F3AF22;
        }
        .textCenter{
            text-align: center;
            font-size: 1.2em;
            width: 80%;
            }
        .vidHeader{
            font-size: 4em;
            width: 80%;
            margin: 0 auto;
            margin-top: 60px;
            line-height: .9em;
            }
    .videodescription{
        font-size: 1.4em;
        width: 80%;
        margin: 0 auto;
        padding: 0px;
        padding-bottom: 40px;
        padding-top: 20px;
        }
}
@media screen and (max-width: 480px){
    .videotitle {
        font-size: 1.5em;
        width: 70vw;
        max-width: 1000px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: -40px;
        color: white;
        background-color: #F3AF22;
        }
        .vidHeader{
            font-size: 3em;
            width: 80%;
            margin: 0 auto;
            margin-top: 60px;
            line-height: .9em;
            }
            .twoColumn{
                display: flex;
                margin: 0 auto;
                flex-direction: column;    
                flex-wrap: wrap;
                justify-content: center;
                gap: 20px;
                max-width: 1600px;
                }
                .horizontalLine{
                    margin: 0 auto;
                    height: 3px;
                    width: 70%;
                    background-color: #241B0F;
                    opacity: 7%;
                    max-width: 1200px;
                    }
                    .columnImage {
                        object-fit: cover;
                        max-height: 1000px;
                        margin: 0 auto;
                        width: 90%;
                        }
                        .columnItem{
                            flex-direction: column;
                            max-width: 100%;
                        }
                        .textLeft{
                        width: 80%;
                        margin: 0 auto;
                        }
}