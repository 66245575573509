  /* BODY CONTENT */
.content1 h1{
font-family: 'Chairdrobe';
font-size: 4em;
color: #241B0F;
text-transform: uppercase;
padding-bottom: 20px;
}
.content1 h2{
    font-family: 'Chairdrobe';
    font-size: 2.5em;
    color: #241B0F;  
    text-transform: uppercase;
padding-bottom: 20px;
}
.content1 h3{
    font-family: 'Chairdrobe';
    font-size: 2em;
    color: #241B0F;  
    text-transform: uppercase;  
    padding-bottom: 20px;
}
.content1 p{
font-family: 'Poppins';
font-size: 1.2em;
line-height: 1.4em;
padding-bottom: 20px;
}
.content1 img{
width: 60%;
border: 3px solid #241B0F;
margin-top: 20px;
margin-bottom: 20px;
}
nav img{
border: none;
margin: 0;
}
.content1{
padding-top: 200px;
text-align: left;
width: 100%;
margin: 0 auto;
}

/* width fix */
.content1 h1, .content1 h2, .content1 h3, .content1 h4, .content1 p {
  width: 70%;
  margin: 0 auto;
  max-width: 1000px;
}

section{
width: 100%;
}
.imageSection{
  margin: 0 auto;
}
ul.ImageList{
display: flex;
justify-content: space-around;
flex-flow: row wrap;
width: 80%;
max-width: 1200px;
}
.ImageList{
text-decoration: none;
list-style: none;
margin: 0 auto;
margin-bottom: 50px;
padding: 0;
display: flex;
justify-content: space-around;
align-content: center;
}
.ImageList a{
margin:0px 20px 20px 0px;
}
.ImageList img {
 width: 200px;
  border: none;
}
.heroImage{
width: 100% !important;
background: #FFF;
margin-bottom: 0px !important;
border: none !important;
}
.HeaderVideo {
object-fit: cover;
box-sizing: border-box;
width: 100%;
position: relative;
z-index: 1;

margin-top: -2vw;
overflow: hidden;
opacity: .7;
}
.mobileHero {
display: none;
}
.white{
  background-color: #FFF;
  width:100;
  max-width: 100%;
  padding:80px 0px;
  margin-bottom: 50px;
}
.white h3 {
text-align: center;
}
.PageHeader{
margin-top:-100px;
top: 50px;
height:auto;
background:  #1e1e1e;
overflow: hidden;
box-sizing:border-box;
border: none;
}

.BrandTitle{
margin-top: 0px;
padding:0px 50px;
}
.PageHeader h1{
  position:absolute;
top: 28vw;
left: 0px;
right: 0px;
color: #FFF;
outline: 2px white solid;
font-size: 5em;
z-index: 2;
padding: 20px 0px;
text-align: center;

}
.imageSection{
position: relative;
margin-top: 75px;
margin-bottom: 20px;
display: flex;
align-items: flex-start;
justify-content: space-between;
gap: 10px; /* Adjust the gap between image and text as needed */
max-width: 1000px;
width: 70%;
}

.imageSection p{
  flex: 1; /* The text content will take available space and set the height */
}
.captionLeft{
  max-width: 30%; /* Ensures image scales responsively */
  height: auto; /* Maintain aspect ratio */
  flex: 1 1 auto; /* Allows the image to grow and shrink */
  border: none !important;
  margin-top: 0px !important;
}

.PrivacyText{
padding-bottom: 200px;
}
.IconBox{
width: 90%;
max-width: 1200px;
margin: 0 auto;
display: flex;
background: #FFFFFF;
justify-content: space-around;
}

.IconBox .iconGroup img{
width: 100%;
border: none;
}
.IconBox h2{
flex-basis: 100px;
margin: 0 auto;
justify-self: center;
}
.IconBox h3{
font-size: 2.5em;
}
.IconBox p {
text-align: center;
}
.IconBox .Arrow{
align-content: center;
justify-content: center;
justify-self: center;
text-align: center;
}
.downArrow{
  display: none;
}
.footer{
padding-bottom: 50px;
}
@media screen and (max-width:1024px){
  .PageHeader h1{
    font-size: 3em;
    z-index: 2;
    padding: 20px 60px;
    }
@media screen and (max-width:780px){
  .ImageList img {
   width: 100px;
    border: none;
  }
  ul.ImageList{
  display: flex;
align-content:center;
flex-flow: row wrap;
  }
  li.ImageList a{
  margin: none !important;
  }
  .PageHeader h1{
    font-size: 1.9em;
    z-index: 2;
    padding: 20px 60px;
    }
}
    .imageSection{
      position: relative;
      display: flex;
      align-items: flex-start;
      gap: 10px; /* Adjust the gap between image and text as needed */
      flex-direction: column;
      width: 100%;
      }
      .imageSection p{
        flex: 1; /* The text content will take available space and set the height */
      }
      .captionLeft{
        max-width: 1000%; /* Ensures image scales responsively */
        height: auto; /* Maintain aspect ratio */
        flex: 1 1 auto; /* Allows the image to grow and shrink */
        border: none !important;
        margin: 0 auto;
      }
      .Arrow{
        display: none;
      }
      .downArrow{
      display: block;
      text-align: center;
      }
      .IconBox{
        flex-flow: column wrap;
        }
        .IconBox h3{
        font-size: 2.5em;
        }
        .IconBox .iconGroup img{
        max-width: 350px;
        justify-self: center;
        margin: 0 auto;
        display: block;
        }
      
}
@media screen and (max-width:480px){
.PageHeader h1{
padding: 0px;
font-size: 3em;
top: 100vw;
overflow: hidden;
width: 90%;
}
.PageHeader {
overflow-y: hidden;
}
.HeaderVideo {
margin-top: 0px;
width: 100%;
}
.desktopHero {
display: none;
}
.mobileHero{
display: block;
}
.Arrow{
display: none;
}
.downArrow{
display: block;
}
.IconBox{
flex-flow: column wrap;
}

}
