
.ContactPage {
    padding-top: 150px;
  }
  
  .showuppls {
    display: block;
    width: 100%;   
  }
  
  .formContainer {
    width: 100%;
    background: #f3af22;
    display: flex;
    justify-content: center;
  }
  .ContactHeader{
font-size: 4em;
margin: 10px;
  }
  .ContactDesc{
width: 80%;
margin: 0 auto;
margin-bottom: 40px;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 60%;
    background-color: #f3af22;
    padding: 20px 20px 60px 20px;
    gap: 20px;
  }
  
  .formRow {
    display: flex;
    flex-direction: row;
    gap: 80px; /* Adds space between the columns */
    margin-bottom: 20px; /* Adds space between rows */
  }
  
  .formLeft,
  .formRight {
    display: flex;
    gap: 20px;
    flex-direction: column;
    gap: 10px;
  }
  
  .formLeft {
    flex: 1;
    
  }
  
  .formRight {
    flex: 2; /* Makes the message field container wider */
  }
  .formRight textarea{
height: 100%;

  }
  
  form label {
    font-size: 1.2em;
  }
  
  form input,
  form textarea,
  form button {
    font-size: 1em;
    padding: 10px;
    width: 100%;
    margin-top: 5px;
    box-sizing: border-box;
  }
  
  form button {
    align-self: flex-start; /* Aligns the button to the start of the form */
    background-color: #241B0F; /* Add any styling you want for the button */
    color: #fff;
    border: none;
    cursor: pointer;
  }

  
  form button:hover {
    background-color: #333; /* Darker background on hover */
  }
 @media screen and (min-width: 1600px){
    form {
        display: flex;
        flex-direction: column;
        width: 900px;
        background-color: #f3af22;
        padding: 20px 20px 60px 20px;
        gap: 20px;
      }  
 }
  @media screen and (max-width: 1200px){
    .formRow {
        display: flex;
        
        flex-direction: row;
        gap: 30px; /* Adds space between the columns */
        margin-bottom: 20px; /* Adds space between rows */
      }
  }
  @media screen and (max-width: 768px){
    .formRow{
        display: flex;
        flex-direction: column;

        }
        .ContactHeader{
        width: 80%;
        margin: 0 auto;
        margin-top: 10px;
        }
        .formLeft, .formRight{
        width: 100%;
        margin: 0 auto;
        padding: 0;
        }
        .formRight textarea{
            height: 200px;
            
              } 
    
  }
  @media screen and (max-width: 480px){
.formRow{
display: flex;
flex-direction: column;
gap: 20px;
}

.formLeft, .formRight{
width: 100%;
margin: 0 auto;
padding: 0;
}
.formRight textarea{
    height: 200px;
    
      }
  }