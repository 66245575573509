.loading-screen {
    background: #fff7f0;
    position: absolute;
    z-index: 1100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    animation: fadeOut 1s ease-out;
    animation-delay: 2500ms;
    animation-fill-mode: both;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  @media screen and (max-width: 480px){
  .loading-screen lottie-player{
  transform: scale(3);
  }
  .loading-screen{
  overflow: hidden;
  }
  }