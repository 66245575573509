.carousel {
    position: relative;
    width: 70%;
    margin: 50px auto 0px auto;
    overflow: hidden;
    z-index: 200;
}
.carousel button{
height: 100%;
}
.slider {
    width: 90%;
    margin: auto;
    display: flex;
}

.slider a {
    flex: 0 0 calc(33.33% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    display: block;
}

.slider img {
    width: 100%;
    
    object-fit: contain;
    display: block;
}

.prev, .next {
    position: absolute;
    font-size: 50px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #FFF7F0;
    color: black;
    border: none;
    padding: 16px;
    cursor: pointer;
    z-index: 1;
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

@media screen and (max-width: 1080px){
    .carousel {
        width: 80%;
        margin: 0px auto 0px auto;
    }

    .slider img {
        flex: 0 0 calc(100% - 30px);
        width: calc(50%);
        height: 450px;
    }

    .prev, .next {
        padding: 0px;
    }
}
@media screen and (max-width: 480px){
.carousel{
height: 200px;
}
.slider img{
height: 200px;
margin: 0 auto;
}
.slider a{
    flex: 0 0 calc(100% - 30px);
}

}