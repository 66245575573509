@font-face {
    font-family: 'Chairdrobe';
    src: url('../resources/fonts/chairdrobe-black-webfont.woff2') format('woff2'),
         url('../resources/fonts/chairdrobe-black-webfont.woff') format('woff'),
         url('../resources/fonts/chairdrobe-black-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
.graphicDesign {
    padding-top: 100px;
}

.ArtHeader{
    margin-top: 50px;
    font-size: 24vw;
    color:#F3AF22;
    opacity: .2;
    position: relative;
    z-index: 1;
}
.PassionHeader{
margin-top: -25vw;
margin-left: 30vw;
font-size: 5vw;
position: relative;
z-index: 2;

}
.graphicDesignBoxes{
display: flex;
flex-flow: row wrap;
justify-content: center;
}

.imgBoxes {
    width: 90%;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    gap: 20px;
    flex-shrink: 1.5;
    
}

.imgBoxes div:not(.box9) {
    border: 2px solid black;
}

.imgBoxes p{
font-size: 3em;
font-family: 'Chairdrobe';
color: #FFFFFF;
position: relative;
justify-content: center;
text-decoration: none;

}
.box1, .box2, .box3, .box4, .box5, .box6, .box7, .box8{
    background-size: cover;
    background-color: rgba(0,0,0,.3);
background-blend-mode: darken;   
align-content: center;

}
.box1 {

    background-image: url('/src/resources/images/brandwork_edited.jpg');

    flex: 1 1 600px;
}

.box2 {
    background-image: url('/src/resources/images/web_design_screenshot.png');
flex: 1 1 400px;
height: 350px;
}

.box3 {
    background-image: url('/src/resources/images/animation_notacat.png');
flex: 1 1 300px;
    height: 350px;
}

.box4 {
    background-image: url('/src/resources/images/VrTrend.jpg');
    flex: 1 1 300px;
    height: 200px;
}

.box5 {
    background-image: url('/src/resources/images/BC2_mockup.jpg');
    flex: 1 1 300px;
    height: 200px;
}

.box6 {
    background-image: url('/src/resources/images/t-shirts.jpg');
    background-position: center;
    flex: 1 1 300px;
    height: 200px;
}

.box7 {
    background-image: url('/src/resources/images/Mario.jpg');
    background-position: center;
    flex: 1 1 300px;
    height: 200px;
}

.box8 {
    background-image: url('/src/resources/images/passion-01.jpg');
    flex: 1 1 700px;
    height: 400px;
}

.box9 {
    flex: 1 1 700px;
}

.box9 p {
    font-family: 'Poppins';
    font-weight: 300;
    padding-left: 20px;
    font-size: 40px;
    text-align: left;
    margin-top: -0px;
    color:#241B0F;
}

.servicesText {
    display: flex;
    justify-content: left;
    margin-top: 50px;
    height: 340px;
}

.services span {
    padding-left: 96px;
    font-size: 200px;
    color: #F3AF22;
    opacity: 20%;
}

.wicdfy span {
    padding-left: 350px;
    margin-top: 120px;
    font-size: 60px;
    color: #241B0F;
}

.offerings {
    display: none;
    height: 440px;
    background-color: #F3AF22;
}

.offerings div {
    text-align: center;
    width: calc(100% / 3);
    color: white;
    position: relative;
    font-size: 68px;
}

.offerings img {
    padding-top: 110px;
    width: 100px;
    height: 100px;
}

.designBox2 img:last-child {
    display: flex;
    transform: translate(865%, 30%);
    width: 35px;
    height: 30px;
}

.offerings div:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 15%; /* adjust this value as needed */
    bottom: 15%; /* adjust this value as needed */
    right: 0%;
    width: 3px; /* or adjust as needed */
    background-color: white; /* or your desired border color */
}

.offerings div:first-child p{
    padding-right: -10%;
}

.offerings div:last-child p{
    padding-left: -10%;
}

.offerings p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
}

.webDesign h2{
font-size: 3em;
margin-bottom: 0px;
}
.webDesign {
    height: auto;
    background-color: #fff;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    outline: 4px solid #fff7f0;;
outline-offset: -20px;
padding-bottom: 20px;
}

.webDesignText {
    width: 50%;
    margin: auto;
    margin-top: 0;
}

.webDesign p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 36px;
    margin-top: 10px;
}
.marioOuterDiv{
    display: none;
}
.marioOuterDiv span {
    margin-top: -100px;
    margin-left: 375px;
    font-size: 72px;
    color: #241B0F;
}
.resume p {
    font-family: 'Poppins';
    font-size: 50px;
    font-weight: bold;
    color: #241B0F;
}


.help {
    margin-top: 200px;
}

.marioInnerDiv {
    width: 90%;
    margin: 86px auto 0px auto;
    border: 2px solid black;
}

.marioInnerDiv img {
    width: 1728px;
}
@media screen and (min-width: 1600px){
    .ArtHeader {
font-size: 350px;
    }
    .PassionHeader {
font-size: 80px;
margin-top: -350px;
}
}
@media screen and (max-width: 1024px){
    .webDesignText{
        width: 70%;
        padding-bottom: 30px;
        }
        .webDesign p{
font-size: 1.5em;
}
.box9 p{
font-size: 1.4em;
}
}

@media screen and (max-width: 768px){
.box9 p{
font-size: 1.2em;
}
.webDesign{
height: auto;

}
.webDesign p{
font-size: 1.3em;
}
.webDesignText{
width: 80%;
padding-bottom: 30px;
}
}