.faq-menu {
    max-width: 1200px;
    margin: auto;
    font-family: 'Poppins';
    line-height: 1.7em;
  }
  h1{
font-family:'Chairdrobe';
font-size: 6em;
  }
  .faq-item {

    margin-bottom: 15px;
  }
  
  .faq-question {
    cursor: pointer;
    padding: 25px;
    background-color: #ffffff;
    font-weight: bold;
    position: relative;
   display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
  }

  .arrow {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 14px; /* Adjust the size of the arrow */
    transition: transform 0.4s ease-in-out, opacity .4s ease-in-out;
    position: relative;
  }
  
  .arrow.open {
    transform: rotate(180deg);
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
  }
  
  .faq-answer {
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out, padding 0.4s ease-in-out, opacity .4s ease-in-out;
    padding: 0 10px;
  }
  
  .faq-answer.open {
    max-height: 1000px; /* Large enough to fit the content */
    padding: 30px;
    opacity: 100%;
    overflow-x: hidden;
  }
  @media screen and (max-width:400px){
    .arrow {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 14px; /* Adjust the size of the arrow */
        transition: transform 0.4s ease-in-out, opacity .4s ease-in-out;
        position: relative;
      }
      .arrow.open {
        transform: rotate(180deg);
        padding-top: 0px;
        padding-bottom: 0px;
        position: relative;
      }
      .faq-question {
        cursor: pointer;
        padding: 35px;
        background-color: #ffffff;
        font-weight: bold;
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
      }
      .faq-answer.open {
      max-height: 1200px;
      }
  }