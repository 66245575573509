.Background{
width: 100%;
position: fixed;
top: 0;
left: 0;
bottom: 0;
right: 0;
z-index: -5;
}
.b-image{
width: 100%;
height: 100%;
background-image: url('../resources/NyPhoto.jpg');
background-size: cover;
background-position: center;
opacity: 45%;
}
/* animations */
.doodles{
width: 100%;
height: 100%;
position: fixed;
z-index: -5;
overflow: hidden;
}
.doodles .angyboy{
display: block;
position: absolute;
left: 30%;
top: 10%;
width: 300px;
height: 300px;
z-index: 99;
rotate: 30deg;
}
.doodles .bigboy{
  display: block;
position: absolute;
  width: 300px;
height: 300px;
  left: 50%;
  top: 75%;
  transform: translateX(250px) scale(1.50);
  rotate: -10deg;
  z-index: -4;
}
.doodles .pouty{
  display: block;
position: absolute;
  width: 200px;
height: 200px;
  left: 30%;
  top: 90%;
  transform: translateX(-300px) scale(1.5);
  rotate: 30deg;
  z-index: -4;
}
.doodles .birdy{
  display: block;
position: absolute;
  width: 200px;
height: 200px;
  left: 30%;
  top: 40%;
  rotate: 30deg;
  z-index: -4;
}
.doodles .stumpy{
  display: block;
position: absolute;
  width: 100px;
height: 100px;
  left: 50%;
  top: 15%;

  z-index: -4;
  transform: scale(.65) translateX(340px);
}
.doodles .bumpy{
  display: block;
position: absolute;
  width: 100px;
height: 100px;
  left: 40%;
  top: 15%;

  z-index: -4;
  transform: scale(.45) rotate(15deg) translateX(-800px) translateY(200px);
}
.CreativeText{
position: fixed;
top: 40%;
margin: 0 auto;
text-align: center;
width: 100%;
color: white;
font-size: 16rem;
text-shadow: 20px 20px 40px rgba(0, 0, 0, 0.2);
z-index: -1;
}
.CreativeText {
  animation: fadeIn1 2s ease-in-out;
  animation-delay: 3s;
  animation-fill-mode: both;
}

@keyframes fadeIn1 {
  0% { opacity: 0; scale: .75; }
  100% { opacity: 1; scale: 1; }
}
.content{
display: block;
position: absolute;
top: 100vh;
width: 100%;
}
.PortfolioSlideShow {
    background: none;
    position: relative;
    z-index: 200;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 50px;
    justify-content: center;
    box-sizing: border-box;
  }
  
.IntroVideoContainer{
    display: inline-block;
    position: relative;
    width: 90%;
    box-sizing: border-box;
}
  .IntroVideoContainer::before {
content: '';
position: absolute;
box-sizing: border-box;
top: 0;
left: 0;
right: 0;
bottom: 0;
width: 100%;
margin-bottom: 3px;
margin-right: 1px;
background-color: rgba(0, 0, 0, 0.25); /* Adjust opacity as needed */
z-index: 1; /* Place overlay behind video */
  }
  .IntroVideoContainer video{
width: 100%;
box-sizing: border-box;
  }
  
  .PortfolioSlideShow span {
    color: #ffffff;
    text-align: center;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
width: 80%;
    z-index: 2;
    font-size: 3.25vw;
   text-shadow: 10px 20px 10px rgba(0, 0, 0, 0.2);
  }
  
  .PortfolioSlideShow span strong {
  font-size: 3em;
  }
.brandTitle h2{
padding-top: 100px;
}
  .brand {
    font-size: 16vw;
    color: #F3AF22 ;
    opacity: 20%;
    background-color: #fff7f0;
    width: 100%;
    position: relative;
  }
  
  .identity  {
    position: absolute;
    font-size: 6vw;
    color: #241B0F;
    width: 100%;
    left: 0;
    margin-top: -10vw;
    
  }
  .backgroundColor{
    background: #fff7f0;
    width: 100%;
    height: auto;
  }
  .identitySection img {
    width: 60vw;
    height: auto;
  }
  
  .descriptionSection {
    width: 60vw;
    margin: 0 auto;
  }
  
  .description {
    font-size: 2.5em;
  }
  
  .descriptionSmall {
    font-size: 1.5em;
  }
  
  .imgSection {
    width: 80%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: auto;
    position: relative;
  }
    
  .imgSection::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 1px;
    background-color: black;
  }
    
  .imgColumn img {
    flex: 10%;
    width: 275px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    border: 1px solid black;
  }
  
  .exposition {
    font-family: 'Lato';
    font-size: 42px;
    width: 69%;
    font-weight: 300;
    border: 3px solid #F3AF22;
    margin: 100px auto;
    margin-bottom: 0px;
    padding: 40px 0px;
    
  }
  
  .exposition p {
    margin: 20px;
    padding: 0px 40px;
  }
  
  .videographySection {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #241B0F;
    margin-bottom: 150px;
  }
  
  .videographyHeader {
    position: absolute; /* Position the categoryHeader relative to welcomeSection */
    width: 70%; /* Take full width of welcomeSection */
    text-align: center;
    padding-top: 100px;
    top: 0;
    font-family: 'Lato';
    font-weight: 700;
  }
    
  .videographyHeader span {
    font-size: 56px;
    font-weight: 500;
    line-height: 2px;
    vertical-align: middle;
    position: relative;
    top: -50px;
  }
  
  .videographyHeader::before,
  .videographyHeader::after {
    content: "";
    position: absolute;
    top: 50%;
    width: calc(27% - 100px); /* Adjust the width of dashes */
    height: 10px; /* Adjust height of dashes */
    background-color: #F3AF22; /* Adjust color as needed */
  }
    
  .videographyHeader::before {
    left: 0;
    margin-left: -130px; /* Half the width of the dashes */
  }
  
  .videographyHeader::after {
    right: 0;
    margin-right: -130px; /* Half the width of the dashes */
  }
    
  iframe {
    width: 80vw;
    height: 45vw;
  }
  
  .PhilHeader {
    margin-top: 0px;
    font-size: 12vw;
    color:#F3AF22;
    opacity: .2;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
  }
  
  .ChaseHeader {
    margin-top: -16.5vw;
    margin-left: 25vw;
    font-size: 4vw;
    position: relative;
    z-index: 2;
    overflow-x: hidden;
  }
  
  .PhilDesc {
    height: 340px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: 4px solid #fff7f0;;
    outline-offset: -1.5vw;
  }
  
  .PhilBody {
    width: 60%;
    font-size: 1.8em;
    line-height: 1.4;
  }
  .camera {
  width: 25%;
  margin-top: 20px;
  margin-bottom: -2.5%;
  }
  .Bottom{
position: relative;
display: block;
  }
  /* MEDIA QUERIES */

@media screen and (min-width: 1600px){
  .PhilDesc{
    width: 1400px;
    margin: 0 auto;
  }
  .camera{
  width: 450px;
  margin-bottom: -15px;
  }
  .PhilHeader{
  font-size: 13.5em;
  margin-top: 0;
  margin: 0 auto;
  }
  .ChaseHeader{
  font-size: 4.5em;
  margin-top: -100px;
  margin-left: 500px;
  }
  .content iframe{
  width: 1280px;
  height: 720px;
  margin: 0 auto;
  }
}
@media screen and (max-width: 1600px) {
  .CreativeText {
    font-size: 16vw;
  }   

}

@media screen and (max-width: 1280px) {
  .background-image::before{
  overflow-x: hidden;
  }
  .CreativeText {
    font-size: 16vw;
  }

  .identitySection .brand {
    margin-top: 100px;
  }

  .categoryHeader {
    position: relative;
    width: 70%;
    text-align: center;
    padding-top: 100px;
    top: 0;
    font-family: 'Poppins';
    font-weight: 400;
  }
  
  .categoryHeader span {
    font-size: 0.7rem;
    padding: 0px;
    letter-spacing: 5px;
  }
  
  .categoryHeader::before,
  .categoryHeader::after {
    width: calc(22% - 60px);
    height: 2px;
  }
  
  .categoryHeader::before {
    margin-left: -60px;
  }
  
  .categoryHeader::after {
    margin-right: -60px;
  }

  .welcomeContent {
    padding-top: 0px;
    margin-top: -50px;
  }
  
  .welcomeContentLeft {
    padding-left: calc(100% / 20);
  }
  
  .welcomeContentLeft span:first-child::after {
    content: "";
    display: inline-block;
    width: 16vw;
    height: 3px;
    background-color: #241B0F;
    margin-left: 30px;
    vertical-align: middle;
    position: absolute;
  }
  
  .welcomeContentRight {
    width: calc(100% / 2);
    text-align: center;
    margin-right: -60px;
    margin-top: 20px;
  }
  
  .welcomeContentLeft p, .welcomeContentRight p {
    font-family: 'Lato';
    font-weight: 300;
    font-size: 40px;
  }
  
  .welcomeContentLeft span {
    display: block;
    font-size: 80px;
    padding: 60px 10px;
    line-height: 2px;
    position: relative;
    text-align: left;
    letter-spacing: 3px;
  }
  
  .welcomeSection::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 60%;
    height: 1px;
    background-color: #241B0F;
    margin-bottom: 80px;
  }

  .identitySection {
    margin-top: 100px;
  }
  
  .brand {
    font-size: 13vw;
    color: #F3AF22 ;
    opacity: 20%;
    margin-top: -200px;
  }
  
  .identity {
    color: #241B0F;
    
  }

  .imgSection {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    position: relative;
  }
  
  .imgSection::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 1px;
    background-color: black;
  }
  
  .imgColumn img {
    flex: 20%;
    max-width: 80%;
    margin: 0px auto 100px auto;
    height: auto;
    border: 1px solid black;
  }

  .exposition {
    font-family: 'Lato';
    font-size: 36px;
    width: 80%;
    font-weight: 300;
    border: 3px solid #F3AF22;
    margin: 100px auto;
    margin-bottom: 0px;
    padding: 40px 8vw;
  }

  .videographySection {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #241B0F;
    margin-bottom: 150px;
  }
  
  .videographyHeader {
    position: absolute; /* Position the categoryHeader relative to welcomeSection */
    width: 100%; /* Take full width of welcomeSection */
    text-align: center;
    padding-top: 100px;
    top: 0;
    font-family: 'Lato';
    font-weight: 400;

  }
  
  .videographyHeader span {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2px;
    vertical-align: middle;
    position: relative;
    top: -50px;
  
  }
  
  .videographyHeader::before{
    width: calc(10%); /* Adjust the width of dashes */
    height: 8px; /* Adjust height of dashes */
    background-color: #F3AF22; /* Adjust color as needed */
    margin-left: 0;
    position: absolute;
    float: left;
    left: 0;

  }
  .videographyHeader::after {
    width: calc(10%); /* Adjust the width of dashes */
    height: 8px; /* Adjust height of dashes */
    background-color: #F3AF22; /* Adjust color as needed */
 margin-right: 0px;
    position: absolute;
    float: right;
 

  }

}

@media screen and (max-width: 1024px) {

  .welcomeSection {
    height: auto;
    padding: 20px;
  }

  .welcomeContentLeft, .welcomeContentRight {
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  .welcomeContentLeft span, .welcomeContentRight p {
    font-size: 2rem;
  }

  .identitySection {
    height: auto;
    padding: 20px;
  }

  .imgSection {
    flex-flow: row wrap;
    align-items: center;
  }

  .imgColumn img {
    width: 90%;
    margin: 10px 0;
  }

  .exposition {
    font-size: 1.5rem;
    padding: 20px;
    margin-bottom: 0px;
  }

  .PhilBody, .PhilDesc p{
    width: 60%;
    font-size: 1.2em;
    line-height: 1.4;
    }
}

@media screen and (max-width: 768px) {

  .CreativeText {
    font-size: 6rem;
 
  }

  .welcomeContentLeft span, .welcomeContentRight p {
    font-size: 1.5rem;
  }
  .doodles .angyboy{
    display: block;
    position: absolute;
    left: 10%;
    
    top: 10%;
    width: 300px;
    height: 300px;
    z-index: 99;
    rotate: 30deg;
    }
    .doodles .bigboy{
      display: block;
    position: absolute;
      width: 300px;
    height: 300px;
      left: 30%;
      top: 75%;
      transform: translateX(250px);
      rotate: -10deg;
      z-index: -4;
    }
    .doodles .pouty{
      display: block;
    position: absolute;
      width: 200px;
    height: 200px;
      left: 20%;
      top: 75%;
      transform: translateX(-100px);
      rotate: 30deg;
      z-index: -4;
    }
    .doodles .birdy{
      display: block;
    position: absolute;
      width: 200px;
    height: 200px;
      left: 15%;
      top: 40%;
      rotate: 30deg;
      z-index: -4;
    }
    .doodles .stumpy{
      display: block;
    position: absolute;
      width: 100px;
    height: 100px;
      left: 30%;
      top: 15%;
    
      z-index: -4;
      transform: scale(.65) translateX(340px);
    }
    .doodles .bumpy{
      display: block;
    position: absolute;
      width: 100px;
    height: 100px;
      left: 20%;
      top: 15%;
    
      z-index: -4;
      transform: scale(.45) rotate(15deg) translateX(-250px) translateY(100px);
    }

  .brand {
    margin-top: 0px;
  }

  .brandTitle {
    margin-top: -60px;
  }

  .identitySection {
    margin-top: 0px;
  }

  .identitySection img {
    width: 80vw;
  }

  .descriptionSection {
    width: 80vw;
    font-size: .6em;
    margin-bottom: 20px;
  }

  .exposition {
    margin-bottom: 0px;
    padding-bottom: 20px;
    position: relative;
    z-index: 3;
  }
  .exposition p{
  font-size: .6em;
  margin: 0px;
  }
.Bottom{
  padding-top: 20px;
  background-color: #fff7f0;
}
  .videographyHeader span {
    font-size: 1.3em;
    font-weight: 400;
  }

  .videographySection {
    margin-bottom: 100px;
  }

  .PhilHeader {
    font-size: 15vw;
    margin-top: 0px;
  }
.PhilDesc{
  outline-offset: -15px;
  padding-top: 50px;
  padding-bottom: 40px;
}
  .ChaseHeader {
    font-size: 5vw;
    margin-top: -19vw;
    margin-left: 0;
  }
}

@media screen and (max-width: 480px) {
  html {
    overflow-x: hidden;
  }


  .CreativeText{
    font-size: 6rem;
    top: 37.5%;
  }

  .identitySection .brand {
    font-size: 5.3rem;
    margin-top: 0px;
  }

  .brandTitle {
    margin-top: 0px;
  }
  .brandTitle h2{
    padding-top: 20px;
  }

  .identitySection{
    margin-top: 0px;
  }

  .identity {
    font-size: 2rem;
    margin-bottom: 10px;
    transform: translateY(-20px);
  }

  .identitySection img {
    width: 80vw;
    margin-top:50px;
  }

  .descriptionSection {
    width: 80vw;
  }

  .description {
    font-size: 1.2em;
  }

  .descriptionSmall {
    font-size: .9em;
  }

  .exposition {
    position: relative;
    margin-top: 0;
    transform: translateY(20px);
  }

  .videographyHeader {
    width: 100%;
  }

  .videographyHeader span {
    font-size: 1.4rem;
  }

  .videographyHeader::before {
    margin-left: 0;
    position: absolute;
    float: left;
    left: 0;
  }

  .videographyHeader::after {
    margin-right: 0;
    position: absolute;
    float: right;
    right: 0;
  }
}
@media screen and (max-width: 400px){
  .brandTitle .brand{
    font-size: 2.9em;
  }
  .brandTitle span{
  font-size: 1em;
  margin-top: 0px;
  }
  .brandTitle h2{
    margin: 0 auto;
  }
.PhilDesc{
height: auto;
margin-top: 20px;
padding-top: 20px;
}
.PhilBody, .PhilDesc p{
font-size: 1em;
}
.videographyHeader span{
font-size: 1.1em;
}
}