.typewriter {
    font-size: 60px;
    font-family: 'Poppins';
    font-weight: 300;
    letter-spacing: 2px;
    padding-top: 100px;
    padding-bottom: -10px;
}

.typewriter p {
    width: 1080px;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    display: inline-block;
}

.typing {
    animation: typing 3s steps(40, end) forwards, blink-caret 1.55s step-end infinite;
}

.blinking {
    animation: blink-caret 1.55s step-end infinite;
}

@keyframes typing {
    from { width: 0; }
    to { width: 57%; }
}

@keyframes blink-caret {
    0%, 100% { border-right: 2.5px solid transparent; }
    50% { border-right: 2.5px solid #F3AF22; }
}

.bottomParent {
    background: #FFF7F0;
    height: auto;
    position: relative;
}

.lottie-parent lottie-player {
    height: 820px;
}

.lottie-parent {
    height: 400px;
    padding-bottom: 100px;
}

.text-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 266px;
}

span {
    font-size: 13vw;
    text-align: center;
    position: absolute;
}

.no-stroke {
    color: #F3AF22;
    z-index: 1;
}

.stroke {
    padding-left: 25px;
    padding-bottom: 10px;
    -webkit-text-stroke: .18vw #241B0F;
    color: transparent;
    z-index: 2;
}

.buttonContainer {
    margin: 50px auto 0px auto;
    width: 600px;
    background: #FFF7F0;
}

.buttonDiv {
    width: 100%;
    height: 150px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.buttonDiv p {
    text-decoration: none;
    color: #F3AF22;
    font-size: 60px;
    font-weight: bold;
}

.buttonLink {
    text-decoration: none;
}

.socials {
    padding: 108px 0px;
}

.socials img {
    height: 140px;
    margin: 0px 25px;
    filter: drop-shadow(1px 5px 20px rgba(0,0,0,0.09));
}

.socialLink img:hover {
    transform: scale(1.1);
    transition: all .13s ease-in-out;
}

.socialLink img:not(:hover) {
    transform: scale(1);
    transition: all .13s ease-in-out;
}

.footer {
    height: auto;
 
    display: flex;
    flex-flow: column wrap;
    padding-bottom: 25px;
}
.footerparent{
    background-color: rgba(36, 27, 15, 0.1);
}
.footerparent p{
font-size: smaller;
margin-bottom: 0;
padding-bottom: 20px;
color: white;
}

.footer a:first-of-type {
    padding-top: 20px;
}


.footerlinks {
    color: white;
    font-family: 'Poppins';
    text-decoration: none;
    padding-top: 20px;

}

.footerlinks:hover {
    color:#F3AF22;
}

@media screen and (max-width: 1280px) {
    .typewriter {
        font-size: 30px;
        font-family: 'Poppins';
        font-weight: 300;
        letter-spacing: 2px;
        padding-top: 50px;
        padding-bottom: 0px;
    }
    
    .typewriter p {
        width: 90%;
        overflow: hidden; /* Ensures the content is not revealed until the animation */
        border-right: 3px solid #F3AF22; /* The typewriter cursor */
        white-space: nowrap; /* Keeps the content on a single line */
        margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    }
  
    .typing {
        animation: 
            typing 3s steps(40, end),
            blink-caret .75s step-end infinite;
    }
  
    @keyframes typing {
        from { width: 0 }
        to { width: 80% } /* Adjust this value based on your design */
    }
    
    @keyframes blink-caret {
        from, to { border-color: transparent }
        50% { border-color: #F3AF22; }
    }

    .lottie-parent {
        height: 300px;
        margin-top: -150px;
        padding-bottom: 0px;
    }
    
    .text-container {
        margin-top: 80px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 266px;
    }
    
    span {
        font-size: 10vw;
        text-align: center;
        position: absolute;
    }
    
    .no-stroke {
        color: #F3AF22;
        z-index: 1;
    }
    
    .stroke {
        padding-left: 9px;
        padding-bottom: 8px;
        -webkit-text-stroke: .2vw #241B0F;
        color: transparent;
        z-index: 2;
    }
    
    .buttonContainer {
        margin: 0px auto 0px auto;
        width: 100%;
        background: #FFF7F0;
    }
    
    .buttonDiv {
        width: 200px;
        height: 60px;
        background-color: white;
        display: flex;
        margin: 0 auto;
    }
    
    .buttonDiv p {
        text-decoration: none;
        color: #F3AF22;
        font-size: 20px;
        font-weight: bold;
    }
    
    .buttonLink {
        text-decoration: none;
    }
    
    .socials {
        padding: 80px 0px;
    }
    
    .socials img {
        height: 140px;
        margin: 0px 0px;
        filter: drop-shadow(1px 5px 20px rgba(0,0,0,0.09));
        
    }
    
    .footer {
        height: 110px;
   
    }
    .footerparent p{
    width: 90%;
    margin: 0 auto;
    }
    
}
@media screen and (min-width: 1700px){
    .no-stroke {
        color: #F3AF22;
        z-index: 1;
        font-size: 14em;
    }
    
    .stroke {
        padding-left: 12px;
        padding-bottom: 11px;
        -webkit-text-stroke: 4px #241B0F;
        color: transparent;
        z-index: 2;
        font-size: 14em;
    }
}
@media screen and (max-width: 720px) {
.text-container{
margin-top: 50px;
}
.typewriter{
font-size: 1.3em;
padding-top: 40px;
}
}


@media screen and (max-width: 480px) {
    .typewriter {
        font-size: 1em;
        font-family: 'Poppins';
        font-weight: 300;
        letter-spacing: 1px;
        padding-top: 40px;
        padding-bottom: 0px;
    }
      
    .typewriter p {
        width: 70%;
        overflow: hidden; /* Ensures the content is not revealed until the animation */
        border-right: 3px solid #F3AF22; /* The typewriter cursor */
        white-space: nowrap; /* Keeps the content on a single line */
        margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    }
    .socials {
        width: 90%;
        margin: 0 auto;
    }
    .socials img{
    width: 20%;
    }
    
    .lottie-parent lottie-player {
        height: 600px;
    }
.text-container{
height: 100px;
}
.camera{
width: 40%;
}
}