.about {
    padding-top: 100px;
}

.whoText {
    position: relative;
    margin-top: 100px;
    height: 400px;
}

.whoText span {
    margin-left: -900px;
    font-size: 300px;
    position: absolute;
}

.whoText .no-stroke {
    color: #F3AF22;
    padding-top: 8px;
    z-index: 1;
}

.whoText .stroke {
    padding-left: 12px;
    -webkit-text-stroke: 3.5px #241B0F;
    color: transparent;
    z-index: 2;
}

.line {
    position: absolute;
    top: 42%;
    right: 0;
    width: 600px;
    margin-right: 100px;
    height: 4px;
    background-color: #241B0F;
}

.aboutMeSection {
    background-color: #F3AF22;
}

.MoreInfo{
    padding: 30px;
width: 87%;
display: flex;
gap: 3vw;
background: #ffffff;
flex-direction: row;
margin: 0 auto;
margin-top: 20px;
flex: none;
outline: 4px solid #fff7f0;;
outline-offset: -1.5vw;
}
.MoreInfo img{
width: 30%;
height: auto;
flex: none;
object-fit: cover;
}
.MoreInfo section p{
font-size: 1.6em;

}
.bodyCopy{
    flex: 1 1 300px;
}
.aboutMeSectionText {
    width: 30%;
    margin-left: 135px;
    
}

.aboutMeSectionText p:last-of-type {
    padding-top: 50px;
}

.maCreativeContent {
    width: 95%;
}


.belowContent {
    margin-top: -250px;
}
.bodyCopy p{
text-align: left!important;
margin: 20px;
}
.im25 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 250px;
    margin: auto;
    margin-top: 300px;
    border: 3px solid #F3AF22;
}

.im25 p {
    font-family: 'Poppins';
    font-weight: 300;
    margin: auto 100px;
    font-size: 2em;
    color: #241B0F;
}

.accentPhoto {
    width: 90%;
    height: 972px;
    margin: 50px auto 0px auto;
    border: 1px solid #241B0F;
}

.resume {
    box-sizing: border-box;
}

.first {
    margin-top: 100px;
}

.resume::after {
    content: "";
    display: block;
    width: 70%;
    height: 3px;
    background-color: #241B0F;
    margin: 0 auto;
}

.resume p {
    font-family: 'Poppins';
    font-size: 50px;
    font-weight: bold;
    color: #241B0F;
}

.Pricing h2{
font-size: 5em;
margin-bottom: 20px;
}
.Pricing p{
font-size: 1.6em;
margin-top: 10px;
margin-bottom: 30px;
padding-bottom: 20px;
}
.Pricing {
    outline: 4px white solid;
    width: 60%;
    margin: 0 auto;
}
.help {
    margin-top: 200px;
}
@media screen and (min-width:1600px){
.im25{
width: 1200px;
}
.MoreInfo{
width: 1144px;
outline: 4px solid #fff7f0;;
outline-offset: -20px;
}
}
@media screen and (max-width: 1280px){

    .im25 p {
        font-family: 'Poppins';
        font-weight: 300;
        margin: auto 70px;
        font-size: 2em;
        color: #241B0F;
        padding: 20px 0px;
    }
    .im25 {
    height: auto;
    }
    .MoreInfo section p{
        font-size: 1.3em;
        }

}

@media screen and (max-width: 720px){

    .im25 p {
        font-family: 'Poppins';
        font-weight: 300;
        margin: auto 70px;
        font-size: 1.5em;
        color: #241B0F;
        padding: 20px 0px;
    }
    .im25{
    height: auto;
    }
    .MoreInfo{
    flex-flow: column wrap;
    }
    .MoreInfo img{
    width: 100%;
    }
.resume p{
font-size: 2em;
}


}
@media screen and (max-width: 484px){
h1{
font-size: 2em;
margin-top: 40px;
}
.AboutTitle h1{
font-size: 3em;
}
    .im25 p {
        font-family: 'Poppins';
        font-weight: 300;
        margin: auto 100px;
        font-size: 1em;
        color: #241B0F;
        margin: 0 auto;
        padding: 20px;
    }
    .im25{
    height: auto;
    margin-top: 25px;
    }

    .Pricing h2{
        font-size: 4em;
        margin-bottom: 0px;
        }
        .Pricing p{
        font-size: 1.6em;
        }
.resume{
margin-top: 20px;
}
.Pricing{
margin-bottom: 40px;
outline: 4px white solid;
}
.resume p{
    font-size: 1.3em;
}
.resume::after{
height: 2px;
}
.belowContent{
margin: 0;
padding: 0;
width: 100%;
}
.MoreInfo {
padding: 0px!important;
width: 100% !important;
outline: 4px solid #fff7f0;;
outline-offset: -15px;
}
.MoreInfo .bodyCopy{
font-size: .8em;
}
.bodyCopy p{
padding: 10px;
}


.MoreInfo img{
width: 80%;
margin: 0 auto;
margin-bottom: 20px;
padding-bottom: 20px;
}
.faq-menu h1{
margin-top: 60px!important;
}
}