.photographyh2{
margin: 50px auto 0;
max-width: 1000px;
width: 100%;
font-size: 3em;
}
.photographyh3{
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;
    font-size: 2em;
}
.photographyP{
    margin: 20px auto 40px;
    max-width: 1000px;
    width: 85%;
    font-size: 1.2em;
}
.photographyList{
    margin: 20px auto;
    display: flex;
    max-width: 90%;
    flex-flow: row wrap;
    justify-content: space-around;
    text-align: center;
    font-size: 1em;
    list-style: none;
}
.photographyList li{
  flex-basis: 30%;  
  color:rgba(243, 175, 34,1);
}

.descriptorsOuter {
    padding-top: 100px;
}

.descriptorsInner {
    margin: 10px auto 0px auto;
    width: 80%;
    max-width: 1200px;
    display: flex;
    align-content: center;
    justify-content: space-around;
    padding: 3px;
    background: rgba(243, 175, 34, .5);
height: 60px;
    margin-top: 75px;

}
.descriptorsInner-caption{
    margin-top: 40px;
    }
.photoh1{
font-size: 5em;
width: 100%;
margin: 50px auto;
margin-top: -90px;
background-color:white;
position: absolute;
box-shadow: rgba(0,0,0, .1) 0px 5px 10px;
}
.descriptorsInner a {
    display: block;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 1em;
align-self: center;
    letter-spacing: 3px;
    color: #241B0F;
flex-basis: 20%;
    text-decoration: none;
    transition: all .2s ease-in-out;
}


.descriptorsInner a.active {
    color: #FFF;
    text-decoration: none;
    text-underline-offset: 5px;
    text-decoration-color: #241B0F;
    font-weight: bold;

}

.descriptorsInner a:hover {
    color: #FFF;
    opacity: 75%;
    text-decoration-color: #241B0F;
    background-color:rgba(0,0,0, .1);
    font-weight: bold;
}



.imageContainer {
    padding-top: 75px;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 30% 40% 25%;
    justify-content: space-between;

border: 1px #fff7f0 dashed;
font: 2em/1.6em Arial;

animation: fadeInImage 1s ease-in;
}
.imageContainer img:hover{
cursor: pointer;
}
@keyframes fadeInImage {
from {opacity: 0%;}
to {opacity: 100%;}
}
.leftImages, .middleImages, .rightImages {
    display: grid;
    grid-auto-rows: minmax(auto, auto);

    height: 100%;
    align-content: start;
}

.leftImages img, .middleImages img, .rightImages img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin: 3% 0;
}

.leftImages {
    grid-column: 1;
}

.middleImages {
    grid-column: 2;
}

.rightImages {
    grid-column: 3;
}
.DividerLine{
width: 60%;
margin: 0 auto;
margin-top: 100px;
border: 2px solid rgba(36,27,15,.1);
border-radius: 5px;

}
@media screen and (max-width: 1280px){
.descriptorsInner, .descriptorsInner-caption {
width: 80%;
margin: 0 auto;
font-size: .8em;
}
.descriptorsInner{
    margin-bottom: 40px;
    margin-top: 70px;
    flex-flow: row wrap;
}
.descriptorsInner-caption{
margin-top: 40px;
}
.photoh1 {
    font-size: 3.5em;
    padding: 20px;

    }
}
@media screen and (max-width: 768px){
    .descriptorsInner a {
        font-size: 1.2em;
        flex-basis: 50%;
    }
    .descriptorsInner, .descriptorsInner-caption{
        width: 80%;
    }
    .descriptorsInner{
    margin-top: 70px;
    height: 120px;
    }
    .descriptorsOuter p{
        padding: 0px 40px;
        }
    .photoh1 {
    font-size: 2.5em;
 padding: 20px 0px;
    }
}
@media screen and (max-width: 480px){
    .descriptorsInner a {
        font-size: 1em;
        flex-basis: 100%;
    }

    .photoh1 {
        font-size: 2em;
        padding: 10px 0px;
        }
    .descriptorsOuter p{
    padding: 0px 40px;
    }

    .imageContainer{
    width: 90%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    border: none;
    gap: 5px;
    mask: none;
    }
    .leftImages img, .middleImages img, .rightImages img{
    border: none;
    
    }
    .leftImages {
        margin: 0 auto;
        border: none;
        gap: 5px;
    }
    
    .middleImages {
        margin: 0 auto;
        border: none;
        gap: 5px;
    }
    
    .rightImages {
        margin: 0 auto;
        border: none;
        gap: 5px;
    }
    .DividerLine{
        margin-top: 50px;
    }
    .descriptorsInner{
    margin-top: 100px;
    margin-bottom: 30px;
    font-size: .8em;
    }
    .descriptorsInner a{
        font-weight: 700;
    }
}