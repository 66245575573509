.content404 {
    position: relative;
    padding-top: 110px;
}
  
.text404 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 2;
}

.guy404 {
    z-index: 1;
    opacity: 30%;
  }

.guy404 img {
    max-height: 85vh;
    max-width: 85vw;
    object-fit: contain;
}

@media screen and (max-width: 1600px){
    .content404 {
      padding-top: calc(100px + 25vh);
    }

    .guy404 {
        transform: translate(0, -30%);
    }
}

@media screen and (max-width: 800px){
    .content404 {
      padding-top: calc(100px + 15vh);
    }

    .guy404 {
        transform: translate(0, -40%);
    }
}

@media screen and (max-width: 400px){
    .content404 {
      padding-top: calc(100px + 25vh);
    }

    .guy404 {
        transform: translate(0, -60%);
    }
}