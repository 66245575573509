.hero-image-container {
    position: relative;
    display: block;
    width: 80vw;
    height: 70vh; /* Adjust height as needed */
    overflow: hidden;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: -40px;
  }
  
  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .hero-image.visible {
    opacity: 1;
  }