.nav {
  font-family: 'Poppins';
  position: fixed;
  background-color: white;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100px;
  box-shadow: rgba(0, 0, 0, .16) 0px 3px 6px;
  z-index: 900;

}

.headerIcon {
  height: 100%;
  margin-right: 50px;
}

.nav img {
  float: left;
  width: 150px;
  height: 100%;
}

.nav a:first-of-type {
  margin-left: 0;
}

.nav a {
  margin: 0px 50px;
  justify-content: center;
  align-items: center;
  color: #241B0F;
  text-decoration: none;
  font-size: 1.2em;
  transition: transform 0.3s;
}

.nav a::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 3px;
  bottom: -6px;
  left: 0;
  border-radius: 40px;
  background: #241b0f;
  transition: width .3s ease-in-out;
}

.nav a:hover::after {
  width: 100%;
}

.nav .homeImg:hover::after {
  display: none;
}

.nav a:hover {
  color: #F3AF22 ;
  text-shadow: 0 0 0.1px #241B0F;
  text-decoration: none;
  text-decoration-color: #241B0F;
  transform: scale(1.00);
  transition: all .3s ease-in-out;
}

.verticalMenu {
  margin-top: 72px;
  box-shadow: 0px 20px 15px rgba(0, 0, 0, 0.5);
  padding-bottom: 20px;
  background: #fff7f0;
}

.verticalMenu a {
  margin: auto;
  display: block;
  width: 100vw;
  font-size: 1.4em;
  font-weight: 900;

  padding: 25px 0px;
  background-color: rgba(255, 247, 240, 0.98);
}

.verticalMenu a::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 0;

}

.verticalMenu a:hover {
  transform: scale(1);
}

@media screen and (max-width: 484px){
  .nav{
  position: fixed;
  top: 0;
  }
}